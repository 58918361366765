import './style.scss'
import React from 'react'
// import Img from 'gatsby-image'
import Intro from '../../../animations/Intro'
import { useInView } from 'react-intersection-observer'
import { Link } from 'gatsby'

export default function ISFAQ({ data }) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  return (
    <div ref={io} id="is-faq" className="">

      <div className="wrap">
        <div className="leftside">
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={400} mounted={true} stay={true}>

            <div className="content">
              <h2>Frequently asked Questions</h2>

              <h6>Are Scholarships Available?</h6>
              <p>Yes, Miami Regional University has scholarships available to those who qualify. Click on the following link: </p>
              <a href="https://cms.mru.edu/wp-content/uploads/2024/04/MRU-International-Professionals-Scholarship-Rev.pdf" className='button bold' target="_blank" rel="noreferrer">International Professionals Scholarship</a >

              <h6>What is Credential Evaluation?</h6>
              <p>Unless you hold an IB Diploma or have completed your high school/university degree in the United States, you will need to submit a credential evaluation report. This report will assess your foreign high school/university qualifications and certify that they are equivalent to the same standard level of qualification in the United States. </p>

              <h6>How to obtain a Credential Evaluation Report?</h6>
              <p>You can request a credential evaluation report to any credential evaluator listed by the National Association of Credential Evaluation Services (<a href="https://www.naces.org/members" target='_blank' className='blue underscore'>NACES</a>). Ensure you request a course-by-course evaluation with a GPA.  </p>

              <p>The Evaluation report should be sent directly to the University via email or hard copy. Consult with your credential evaluator to determine the most convenient method for submitting the evaluation to the University.</p>

              <p><strong className='uppercase'>Via email:</strong> <a href="mailto:international@mru.edu" target='blank' className='blue underscore'>international@mru.edu</a> </p>
              <p><strong className='uppercase'>Hard copy:</strong>  700 S Royal Poinciana Blvd #100, Miami Springs, FL 33166, Office of Admissions, Miami Regional University</p>

            </div>
          </Intro>
        </div>
        <div className="rightside">
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} mounted={true} stay={true}>
            <div className="content">

              <h6>How to obtain an English Proficiency Test?</h6>
              <p>The University accepts several official English language proficiency tests. The minimum required scores for each test are listed below.  Send a copy of the test results to <a href="mailto:international@mru.edu" target='blank' className='blue underscore'>international@mru.edu</a></p>
              <div className='table container fs-85'>
                <table>
                  <thead>
                    <tr>
                      <th className='title'>TEST</th>
                      <th>Minimum Score</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>TOEFL, Paper-based</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td>TOEFL, Computer-based</td>
                      <td>173</td>
                    </tr>
                    <tr>
                      <td>TOEFL, Internet-Based Test (iBT)</td>
                      <td>61</td>
                    </tr>
                    <tr>
                      <td>IELTS</td>
                      <td>6.0</td>
                    </tr>
                    <tr>
                      <td>NGL Online Placement Test</td>
                      <td>C1</td>
                    </tr>
                    <tr>
                      <td>Duolingo English Test</td>
                      <td>95</td>
                    </tr>
                    <tr>
                      <td>TOEIC</td>
                      <td>750</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className='remove-bottom-margin padd-top padd-bottom'><strong className='uppercase'>Required Minimum Balance</strong> to demonstrate financial capacity for international students to study at MRU is: </p>
              <div className='table container fs-85'>

                <table className='margin-top-off'>
                  <thead>
                    <tr>
                      <th className='title'>Program</th>
                      <th>Minimum balance required</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Undergraduate</td>
                      <td>$34,735</td>
                    </tr>
                    <tr>
                      <td>graduate</td>
                      <td>$36,895</td>
                    </tr>

                  </tbody>
                </table>
              </div>

              <p>Once you submit the aforementioned documents, they will be reviewed by the Designated School Official at MRU. Upon review, a Form I-20 will be sent to your email address as an electronic file.  </p>

            </div>
          </Intro>
        </div>
      </div>
    </div>
  )
}